import React, { useState, useMemo } from 'react';
import DotPhraseTextArea from './DotPhraseTextArea';
import AudioTranscription from './buttons/AudioTranscriptionButton';
import BillingButton from './buttons/BillingButton';
import HashProblemsButton from './buttons/HashProblemsButton';

export const sectionLabels = {
    consultInfo: 'Consult Information',
    hpi: 'History of Present Illness',
    medicalHistory: 'Medical History | Surgical History | Allergies',
    socialHistory: 'Social History',
    antimicrobials: 'Antimicrobials',
    physicalExam: 'Physical Examination',
    labs: 'Labs | Microbiology',
    imaging: 'Radiology | Cardiology',
    recommendations: 'Assessment and Recommendations'
};

export const defaultSectionText = {
    consultInfo: 'Consulting Physician: \nConsulting Service: \nReason for Consultation: ',
    medicalHistory: 'Medical History:\n\nSurgical History:\n\nAntimicrobial Allergies:',
    socialHistory: 'Social History:\n',
    antimicrobials: 'Antimicrobials:\n',
    labs: 'Labs:\n\nMicrobiology:\n'
};


// Function to stylize numbers in the patient name input
const PatientNameInput = ({ value, onChange, placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);
    const { displayText, numbers } = useMemo(() => {
        const nums = (value.match(/\d+(?:\/\d+)*(?:\/\d+)*/g) || []).map(num => num.toString());
        const masked = value.replace(/\d+(?:\/\d+)*(?:\/\d+)*/g, match => '\u200B'.repeat(match.length));
        return { displayText: masked, numbers: nums };
    }, [value]);

    return (
        <div className="relative">
            <textarea
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                className="block w-full rounded-md bg-[#242424] border border-[#323232] focus:border-white focus:outline-none transition duration-150 ease-in-out shadow-sm px-3 py-2 resize-none overflow-hidden"
                style={{
                    caretColor: 'white',
                    color: isFocused ? 'white' : 'transparent',
                    minHeight: '42px',
                    height: 'auto'
                }}
                rows={1}
                onInput={e => {
                    e.target.style.height = 'auto';
                    e.target.style.height = e.target.scrollHeight + 'px';
                }}
                autoComplete="off"
            />
            {!isFocused && (
                <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none px-3 py-2 text-white overflow-hidden">
                    <div className="flex flex-wrap items-center w-full">
                        <span className="whitespace-pre-wrap break-words">
                            {displayText.replace(/\u200B+/g, '')}
                        </span>
                        {numbers.map((num, index) => (
                            <span
                                key={index}
                                className="inline-flex items-center px-1.5 py-0.5 ml-1 rounded-md text-xs bg-blue-500 bg-opacity-20 text-blue-300"
                                style={{ 
                                    wordBreak: 'break-all',
                                    maxWidth: '200px',
                                    overflow: 'hidden'
                                }}
                            >
                                {num}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const NoteEditorSections = ({
    note,
    currentPreset,
    updateNote,
    summarizeNote,
    isLoading,
    handleCopySection,
    handleTranscriptionUpdate,
    startRecordingSession,
    endRecordingSession,
    presetSections,
    actionSuccess,
    refs,
    pinnedSection,
    setPinnedSection,
    collapseHeader
}) => {
    const headerHeight = collapseHeader ? '48px' : '96px';
    const [activeRecordingSection, setActiveRecordingSection] = useState(null);
    const [activeSection, setActiveSection] = useState(null);

    const renderSection = (section, label) => (
        <div 
            className={`mb-8 ${
                pinnedSection === section 
                    ? 'sticky z-10 bg-[#1e1e1e] bg-opacity-70 p-4 shadow-lg border border-blue-500 rounded-lg backdrop-filter backdrop-blur-sm' 
                    : ''
            }`} 
            style={pinnedSection === section ? { top: headerHeight } : {}}
            key={section}
        >
            <div className="flex items-center justify-between mb-1">
                <label htmlFor={section} className="block text-sm font-medium text-[#e0e0e0]">
                    {label}:
                </label>
                <button
                    onClick={() => setPinnedSection(pinnedSection === section ? null : section)}
                    className={`p-1 rounded transition-colors duration-200 flash-button ${
                        pinnedSection === section 
                            ? 'text-blue-500 bg-[#1976d2] bg-opacity-20' 
                            : 'text-[#ababab] hover:text-[#e0e0e0]'
                    }`}
                    title={pinnedSection === section ? "Unpin section" : "Pin section"}
                >
                    <span className={`material-icons text-base transition-transform duration-200 ${
                        pinnedSection === section ? 'transform rotate-90' : ''
                    }`}>
                        push_pin
                    </span>
                </button>
            </div>

            <div className="relative">
                <DotPhraseTextArea
                    section={section}
                    id={section}
                    value={note[section] || defaultSectionText[section] || ""}
                    onChange={(e) => updateNote(section, e.target.value)}
                    ref={refs[section]}
                    isSticky={pinnedSection === section}
                    isRecording={activeRecordingSection === section}
                    onFocus={() => setActiveSection(section)}
                    onBlur={() => setActiveSection(null)}
                />
            </div>

            {pinnedSection !== section && (
                <div className="mt-2">
                    <div className="flex justify-between">
                        <div>
                            {section === 'recommendations' && (
                                <div className="flex items-center gap-2">
                                    <BillingButton 
                                        assessment={note[section] || ''} 
                                        updateNote={updateNote}
                                    />
                                    <HashProblemsButton
                                        assessment={note[section] || ''}
                                        updateNote={(text) => updateNote(section, text)}
                                        buttonClassName="flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out"
                                    />
                                </div>
                            )}
                        </div>
                        <ActionButtons 
                            section={section}
                            handleCopySection={handleCopySection}
                            summarizeNote={summarizeNote}
                            isLoading={isLoading}
                            actionSuccess={actionSuccess}
                        />
                    </div>
                </div>
            )}
        </div>
    );

    const handleRecordingStart = (section) => {
        setActiveRecordingSection(section);
        startRecordingSession(section);
    };

    const handleRecordingEnd = (section) => {
        setActiveRecordingSection(null);
        endRecordingSession(section);
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            <div>
                <label htmlFor="noteTitle" className="block text-sm font-medium text-[#e0e0e0] mb-1">
                    Patient Name:
                </label>
                <PatientNameInput
                    value={note.title === "Untitled Note" ? "" : note.title || ""}
                    onChange={(e) => updateNote('title', e.target.value || "Untitled Note")}
                    placeholder={note.title === "Untitled Note" ? "Untitled Note" : ""}
                />
            </div>

            {presetSections[currentPreset].map(section => 
                renderSection(section, sectionLabels[section])
            )}
            
            <AudioTranscription
                onTranscriptionUpdate={(transcript, isFinal) => 
                    handleTranscriptionUpdate(activeSection, transcript, isFinal)
                }
                onRecordingStart={() => handleRecordingStart(activeSection)}
                onRecordingEnd={() => handleRecordingEnd(activeSection)}
                isActiveSection={activeSection !== null}
            />
        </form>
    );
};

// Action buttons for each section
const ActionButtons = ({ section, handleCopySection, summarizeNote, isLoading, actionSuccess }) => (
    <div className="flex flex-wrap items-center gap-2">
        <button
            type="button"
            onClick={() => handleCopySection(section)}
            className="flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
            title="Copy"
        >
            <span className="material-symbols-outlined text-white text-base">
                {actionSuccess[`${section}-copy`] ? 'check_circle' : 'content_copy'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                Copy
            </span>
        </button>
        <button
            type="button"
            onClick={() => summarizeNote(section)}
            disabled={isLoading[section]}
            className={`flex items-center justify-center px-2 py-1 rounded-md ${
                isLoading[section] ? 'bg-[#323232]' : 'bg-[#2a2a2a] hover:bg-[#323232]'
            } border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
            title="Format with AI"
        >
            {isLoading[section] ? (
                <span className="material-icons text-white text-base animate-spin">
                    hourglass_empty
                </span>
            ) : (
                <span className="material-icons text-white text-base">
                    auto_fix_high
                </span>
            )}
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap ml-1 hidden sm:inline">
                Format
            </span>
        </button>
    </div>
);

export default NoteEditorSections;