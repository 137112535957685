import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import { useSettings } from '../context/SettingsContext';
import darkTheme from '../theme/darkTheme';

const SettingSection = ({ title, children }) => (
    <div className="mb-8 bg-[#242424] rounded-lg p-6 shadow-md border border-[#323232]">
        <h3 className="text-lg font-semibold mb-4 text-[#ffffff] border-b border-[#323232] pb-2">{title}</h3>
        {children}
    </div>
);

const presets = [
    { full: 'ID Consult', short: 'ID' },
    { full: 'ID Follow Up', short: 'Follow' },
    { full: 'Outpatient', short: 'Outpt' }
];

const aiProviders = [
    { value: 'claude', label: 'Claude' },
    { value: 'azure', label: 'Azure' }
];

const SettingsDialog = ({ isOpen, setIsOpen, localSettings, handleSettingChange, handleSaveSettings, isSaving, saveError }) => (
    <div className="fixed inset-0 bg-[#121212] bg-opacity-50 flex items-center justify-center z-[9999] font-sans p-4">
        <div className="bg-[#1e1e1e] p-4 sm:p-6 rounded-lg shadow-xl border border-[#323232] w-full max-w-2xl relative z-[10000] max-h-[90vh] overflow-y-auto text-gray-100">
            <button
                onClick={() => setIsOpen(false)}
                className="absolute top-2 right-2 text-[#ababab] hover:text-white focus:outline-none focus:ring-1 focus:ring-white"
                aria-label="Close"
            >
                <span className="material-icons">close</span>
            </button>
            <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">Settings</h2>
            
            <SettingSection title="Default Preset">
                <div className="inline-flex rounded-md shadow-sm" role="group">
                    {presets.map((preset, index) => (
                        <button
                            key={preset.full}
                            type="button"
                            onClick={() => handleSettingChange('defaultPreset', preset.full)}
                            className={`
                                px-4 py-2 text-sm font-medium
                                ${index === 0 ? 'rounded-l-lg' : ''}
                                ${index === presets.length - 1 ? 'rounded-r-lg' : ''}
                                ${localSettings.defaultPreset === preset.full
                                    ? 'bg-[#1976d2] text-white'
                                    : 'bg-[#2a2a2a] text-[#e0e0e0] hover:bg-[#323232] hover:text-white'
                                }
                                focus:z-10 focus:ring-1 focus:ring-white focus:text-white flash-button
                                transition-colors duration-200 ease-in-out
                                border-t border-b ${index === 0 ? 'border-l' : ''} ${index === presets.length - 1 ? 'border-r' : ''}
                                border-[#404040]
                            `}
                        >
                            <span className="hidden sm:inline">{preset.full}</span>
                            <span className="sm:hidden">{preset.short}</span>
                        </button>
                    ))}
                </div>
            </SettingSection>

            <SettingSection title="AI Provider">
                <div className="relative inline-flex items-center p-1 rounded-lg bg-[#2a2a2a] border border-[#404040]">
                    <div
                        className="absolute h-[calc(100%-8px)] top-1 transition-all duration-200 ease-in-out rounded-md bg-[#1976d2]"
                        style={{
                            width: 'calc(50% - 4px)',
                            left: localSettings.aiProvider === 'claude' ? '4px' : 'calc(50% + 0px)',
                            outline: '1px solid white',
                        }}
                    />
                    {aiProviders.map((provider) => (
                        <button
                            key={provider.value}
                            onClick={() => handleSettingChange('aiProvider', provider.value)}
                            className={`
                                relative z-10 px-6 py-1.5 text-sm font-medium rounded-md
                                transition-colors duration-200 ease-in-out
                                ${localSettings.aiProvider === provider.value
                                    ? 'text-white'
                                    : 'text-[#e0e0e0] hover:text-white'
                                }
                                focus:outline-none
                            `}
                        >
                            {provider.label}
                        </button>
                    ))}
                </div>
            </SettingSection>

            <SettingSection title="Signing Out to:">
                <div className="flex items-center space-x-2 px-1">
                    <span className="text-white whitespace-nowrap">Dr. </span>
                    <input
                        type="text"
                        id="signoutPerson"
                        value={localSettings.signoutPerson}
                        onChange={(e) => handleSettingChange('signoutPerson', e.target.value)}
                        placeholder="Person name"
                        className="bg-[#2a2a2a] text-white p-2 rounded flex-grow h-10 min-w-0 border border-[#323232]"
                    />
                    <span className="text-white whitespace-nowrap">on</span>
                    <ThemeProvider theme={darkTheme}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={localSettings.signoutDate}
                                onChange={(newValue) => handleSettingChange('signoutDate', newValue)}
                                showTodayButton
                                slotProps={{
                                    actionBar: {
                                        actions: ['today'],
                                    },
                                    popper: {
                                        sx: { zIndex: 10003 },
                                    },
                                }}
                                renderInput={(params) => (
                                    <div className="relative flex-shrink min-w-[120px]">
                                        <input
                                            {...params.inputProps}
                                            className="bg-[#2a2a2a] text-white p-2 rounded w-full pr-10 h-10 cursor-pointer text-sm focus:ring-white"
                                            placeholder="Select date"
                                            onClick={(e) => {
                                                e.target.blur();
                                                params.inputProps.onClick(e);
                                            }}
                                        />
                                        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-[#ababab] pointer-events-none">
                                            <span className="material-symbols-outlined">calendar_today</span>
                                        </span>
                                    </div>
                                )}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                </div>
            </SettingSection>

            {saveError && <p className="text-red-500 mb-3 sm:mb-4">{saveError}</p>}

            <div className="flex justify-center mt-6 sm:mt-8">
                <button
                    onClick={handleSaveSettings}
                    disabled={isSaving}
                    className="px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white flash-button border border-[#323232]"
                >
                    {isSaving ? (
                        <>
                            <span className="material-symbols-outlined text-white text-base animate-spin mr-1">hourglass_empty</span>
                            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Saving...</span>
                        </>
                    ) : (
                        <>
                            <span className="material-icons text-white text-base mr-1">save</span>
                            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Save Settings</span>
                        </>
                    )}
                </button>
            </div>

            {saveError && <p className="text-red-500 mt-3 sm:mt-4 text-center">{saveError}</p>}
        </div>
    </div>
);

function SettingsButton() {
    const [isOpen, setIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const { settings, updateSettings } = useSettings();
    const [localSettings, setLocalSettings] = useState({
        ...settings,
    });

    const handleOpenDialog = () => {
        setIsSpinning(true);
        setTimeout(() => setIsSpinning(false), 300);
        setIsOpen(true);
    };

    const handleSettingChange = useCallback((key, value) => {
        setLocalSettings(prevSettings => ({ ...prevSettings, [key]: value }));
    }, []);

    const handleSaveSettings = async () => {
        setIsSaving(true);
        setSaveError(null);
        try {
            await updateSettings(localSettings);
            setIsOpen(false); // Close dialog after successful save
        } catch (error) {
            console.error('Error saving settings:', error);
            setSaveError('Failed to save settings. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        setLocalSettings(prev => ({
            ...settings,
        }));
    }, [settings]);

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded bg-[#2a2a2a] text-white hover:bg-[#323232] flash-button border border-[#323232] transition duration-150 ease-in-out flex items-center justify-center"
                title="Settings"
            >
                <span className={`material-symbols-outlined text-lg sm:text-xl ${isSpinning ? 'animate-spin-once' : ''}`}>settings</span>
            </button>
            {isOpen && ReactDOM.createPortal(
                <SettingsDialog
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    localSettings={localSettings}
                    handleSettingChange={handleSettingChange}
                    handleSaveSettings={handleSaveSettings}
                    isSaving={isSaving}
                    saveError={saveError}
                />,
                document.body
            )}
        </>
    );
}

export default SettingsButton;