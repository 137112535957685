import React from 'react';
import NoteEditorSections from './NoteEditorSections';
import Insights from '../Insights';
import Todo from '../Todo';
import NoteEditorHeader from './NoteEditorHeader';
import SummarizeAllButton from './buttons/SummarizeAllButton';
import MultiInsertButton from './buttons/MultiInsertButton';

function NoteEditorContent({
    activeTab,
    note,
    currentPreset,
    updateNote,
    summarizeNote,
    isLoading,
    handleCopySection,
    handleTranscriptionUpdate,
    startRecordingSession,
    endRecordingSession,
    currentSessionTranscripts,
    currentTranscript,
    activeRecordingSection,
    presetSections,
    actionSuccess,
    refs,
    handlePresetChange,
    handleSummarizeAll,
    isSummarizing,
    pinnedSection,
    setPinnedSection,
    collapseHeader,
}) {
    return (
        <div className="bg-[#1e1e1e] shadow-lg rounded-lg border border-[#2a2a2a]">
            <div className="p-4 sm:p-6">
                {activeTab === 'editor' && (
                    <>
                        <NoteEditorHeader
                            note={note}
                            updateNote={updateNote}
                            currentPreset={currentPreset}
                            handlePresetChange={handlePresetChange}
                            presetSections={presetSections}
                        />
                        <NoteEditorSections
                            note={note}
                            currentPreset={currentPreset}
                            updateNote={updateNote}
                            summarizeNote={summarizeNote}
                            isLoading={isLoading}
                            handleCopySection={handleCopySection}
                            handleTranscriptionUpdate={handleTranscriptionUpdate}
                            startRecordingSession={startRecordingSession}
                            endRecordingSession={endRecordingSession}
                            currentSessionTranscripts={currentSessionTranscripts}
                            currentTranscript={currentTranscript}
                            activeRecordingSection={activeRecordingSection}
                            presetSections={presetSections}
                            actionSuccess={actionSuccess}
                            refs={refs}
                            pinnedSection={pinnedSection}
                            setPinnedSection={setPinnedSection}
                            collapseHeader={collapseHeader}
                        />
                        <div className="mt-6 flex justify-center">
                            <SummarizeAllButton
                                handleSummarizeAll={handleSummarizeAll}
                                isSummarizing={isSummarizing}
                            />
                            <MultiInsertButton
                                key={note.id}
                                note={note}
                                currentPreset={currentPreset}
                                updateNote={updateNote}
                            />
                        </div>
                    </>
                )}
                {activeTab === 'todo' && (
                    <Todo
                        note={note}
                        updateNote={(field, value) => updateNote(field, value)}
                    />
                )}
                {activeTab === 'insights' && <Insights note={note} />}
            </div>
        </div>
    );
}

export default NoteEditorContent;
