import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
    },
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    color: '#e0e0e0',
                    '&.Mui-selected': {
                        backgroundColor: '#1976d2',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#1565c0',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e',
                    zIndex: 10001,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    borderRadius: '0.375rem',
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#2a2a2a',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    '&:hover': {
                        backgroundColor: '#323232',
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#2a2a2a',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2a2a2a',
                    color: '#e0e0e0',
                    '&:hover': {
                        backgroundColor: '#323232',
                    },
                    '&.Mui-focused': {
                        outline: '2px solid white',
                        outlineOffset: '2px',
                        borderRadius: '0.375rem',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #323232',
                    },
                    height: '40px',
                    minWidth: '120px',
                    width: '100%',
                    cursor: 'pointer',
                },
                input: {
                    padding: '8px',
                    height: '100%',
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    zIndex: 10002,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 10002,
                },
            },
        },
    },
});

export default darkTheme;
