export const getNoteDragStyles = (note, draggedNote, draggedOverNote, notes) => {
    if (draggedNote && draggedNote.id === note.id) {
        return "opacity-50";
    }
    if (draggedOverNote && draggedOverNote.id === note.id) {
        const draggedIndex = notes.findIndex(n => n.id === draggedNote.id);
        const targetIndex = notes.findIndex(n => n.id === note.id);
        if (draggedIndex < targetIndex) {
            return "border-b-2 border-blue-500";
        } else {
            return "border-t-2 border-blue-500";
        }
    }
    return "";
};

export const sidebarClasses = {
    container: `fixed top-0 left-0 h-full bg-[#121212] bg-opacity-80 backdrop-filter backdrop-blur-sm shadow-lg
                transition-all duration-300 border-r border-[#2a2a2a] flex flex-col z-50`,
    collapsedWidth: 'w-16',
    expandedWidth: 'w-64'
}; 