import React from 'react';

function DeleteAllConfirmation({ onConfirm, onCancel }) {
    return (
        <div className="fixed inset-0 bg-[#121212] bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#1e1e1e] p-6 rounded-lg shadow-xl border border-[#323232]">
                <h3 className="text-xl font-bold mb-4 text-gray-100">Delete All Notes</h3>
                <p className="mb-6 text-[#e0e0e0]">Are you sure you want to delete all notes? This action cannot be undone.</p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 bg-[#323232] hover:bg-[#2a2a2a] rounded transition duration-150 ease-in-out text-white border border-[#424242] flash-button"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded transition duration-150 ease-in-out text-white border border-[#7c2d2d] flash-button"
                    >
                        Delete All
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteAllConfirmation;