import { sectionHeadings } from './noteFormatUtils';
import highlightTerms from '../data/highlightTerms';

export const handleCopyProblem = (e, problem) => {
    e.preventDefault();
    const contentWithoutHeader = problem
        .slice(1)
        .filter(line => 
            !line.includes("Assessment and Plan:") && 
            !line.includes("Assessment and Recommendations")
        )
        .join('\n');
    navigator.clipboard.writeText(contentWithoutHeader);
    const button = e.currentTarget;
    button.textContent = 'done';
    setTimeout(() => {
        button.textContent = 'content_copy';
    }, 1000);
};

export const renderHighlightedLine = (line, index, value, section, showCopyButton = true) => {
    const segments = [];
    let currentPosition = 0;
    const lowerLine = line.toLowerCase();
    let nonHighlightedText = '';

    const flushNonHighlighted = (position) => {
        if (nonHighlightedText) {
            segments.push(
                <span key={`nh-${position}`}>{nonHighlightedText}</span>
            );
            nonHighlightedText = '';
        }
    };

    while (currentPosition < line.length) {
        let matchFound = false;
        
        if (currentPosition === 0 && line.startsWith('#')) {
            flushNonHighlighted(currentPosition);
            segments.push(
                <span key={currentPosition} style={{ color: '#90caf9' }}>{line}</span>
            );
            currentPosition = line.length;
            matchFound = true;
        } else if (currentPosition === 0) {
            const matchedHeading = Object.values(sectionHeadings)
                .flat()
                .find(heading => line.toLowerCase().startsWith(heading.toLowerCase()));
            
            if (matchedHeading) {
                flushNonHighlighted(currentPosition);
                segments.push(
                    <span key={currentPosition} style={{ color: '#90caf9' }}>
                        {line.slice(0, matchedHeading.length)}
                    </span>
                );
                currentPosition += matchedHeading.length;
                matchFound = true;
            }
        }
        
        if (!matchFound) {
            for (const [term, config] of Object.entries(highlightTerms)) {
                const lowerTerm = term.toLowerCase();
                if (lowerLine.startsWith(lowerTerm, currentPosition) && 
                    (!config.sections || !section || config.sections.includes(section))) {
                    flushNonHighlighted(currentPosition);
                    segments.push(
                        <span key={currentPosition} style={{ color: config.color }}>
                            {line.slice(currentPosition, currentPosition + term.length)}
                        </span>
                    );
                    currentPosition += term.length;
                    matchFound = true;
                    break;
                }
            }
        }

        if (!matchFound) {
            nonHighlightedText += line[currentPosition];
            currentPosition++;
        }
    }

    flushNonHighlighted(currentPosition);

    return (
        <div key={index} style={{ position: 'relative', display: 'block', whiteSpace: 'pre-wrap' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                {segments.length > 0 ? segments : ' '}
                {showCopyButton && line.startsWith('#') && (
                    <button
                        className="material-symbols-outlined absolute left-full ml-2 top-1/2 -translate-y-1/2 pointer-events-auto opacity-0 group-hover:opacity-100 cursor-pointer text-gray-400 hover:text-white transition-opacity duration-200 text-[16px]"
                        onClick={(e) => handleCopyProblem(e, splitIntoProblems(value).find(p => p[0] === line))}
                    >
                        content_copy
                    </button>
                )}
            </div>
        </div>
    );
};

export const splitIntoProblems = (text) => {
    const lines = text.split('\n');
    const problems = [];
    let currentProblem = [];

    lines.forEach((line) => {
        if (line.startsWith('#')) {
            if (currentProblem.length > 0) {
                problems.push(currentProblem);
            }
            currentProblem = [line];
        } else if (line.trim() !== '') {
            currentProblem.push(line);
        }
    });

    if (currentProblem.length > 0) {
        problems.push(currentProblem);
    }

    return problems;
}; 