import React, { useState } from 'react';
import { useSettings } from '../../../context/SettingsContext';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

function MessageButton({ note, currentPreset }) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [copySuccess, setCopySuccess] = useState(false);
    const { settings } = useSettings();

    const handleOpenDialog = () => {
        setIsOpen(true);
        setMessage(''); // Reset the message when opening the dialog
        setError(null); // Also reset any previous errors
    };

    const handleGenerateMessage = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(`${SERVER_URL}/api/notes/generate-message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': sessionStorage.getItem('token')
                },
                body: JSON.stringify({
                    recommendations: note.recommendations,
                    aiProvider: settings.aiProvider
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setMessage(data.message);
        } catch (error) {
            console.error('Error generating message:', error);
            setError('Failed to generate message. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyMessage = () => {
        navigator.clipboard.writeText(message);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    if (!['ID Follow Up', 'ID Consult'].includes(currentPreset)) {
        return null;
    }

    return (
        <>
            <button
                onClick={handleOpenDialog}
                className="flex items-center justify-center px-3 py-1.5 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out"
                title="Generate Message"
            >
                <span className="material-symbols-outlined text-white text-base mr-1">
                    forum
                </span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline">Message</span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap sm:hidden">Msg</span>
            </button>
            {isOpen && (
                <div className="fixed inset-0 bg-[#121212] bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-[#1e1e1e] p-4 sm:p-6 rounded-lg shadow-xl border border-[#323232] w-full max-w-2xl relative">
                        <button
                            onClick={() => setIsOpen(false)}
                            className="absolute top-2 right-2 text-[#ababab] hover:text-white focus:outline-none focus:ring-1 focus:ring-white"
                            aria-label="Close"
                        >
                            <span className="material-icons">close</span>
                        </button>
                        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">Generate Message for Team</h2>
                        <button
                            onClick={handleGenerateMessage}
                            disabled={isLoading}
                            className="mb-3 sm:mb-4 px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white border border-[#323232] flash-button"
                        >
                            {isLoading ? (
                                <>
                                    <span className="material-icons text-white text-base animate-spin mr-1">hourglass_empty</span>
                                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generating...</span>
                                </>
                            ) : (
                                <>
                                        <span className="material-icons text-white text-base mr-1">auto_fix_high</span>
                                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Generate Message</span>
                                </>
                            )}
                        </button>
                        {error && <p className="text-red-500 mb-2">{error}</p>}
                        <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-full h-36 sm:h-48 p-2 bg-[#242424] text-white border border-[#323232] rounded mb-3 sm:mb-4"
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={handleCopyMessage}
                                className="inline-flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] transition duration-150 ease-in-out border border-[#323232]"
                                title="Copy"
                            >
                                <span className="material-icons text-white text-base">
                                    {copySuccess ? 'check_circle' : 'content_copy'}
                                </span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline ml-1">Copy</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default MessageButton;