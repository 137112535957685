import React, { useState, useCallback } from 'react';
import { createDocxFromNote, downloadDocx } from '../../utils/docxExportUtils';
import { useSettings } from '../../../context/SettingsContext';

const ExportDocxButton = ({ note, currentPreset, presetSections }) => {
    const [exporting, setExporting] = useState(false);
    const { settings } = useSettings();

    const handleExport = useCallback(async () => {
        setExporting(true);
        try {
            const blob = await createDocxFromNote(note, currentPreset, presetSections, settings);

            // Create the file name
            const presetName = currentPreset.replace(/\s+/g, '_');
            const patientName = (note.title || 'Untitled_Note').replace(/\s+/g, '_');
            const fileName = `${presetName}_${patientName}.docx`;

            await downloadDocx(blob, fileName);
        } catch (err) {
            console.error('Failed to export note: ', err);
        } finally {
            setExporting(false);
        }
    }, [note, currentPreset, presetSections, settings]);

    return (
        <button
            onClick={handleExport}
            disabled={exporting}
            className="flex items-center justify-center px-3 py-1.5 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
            title="Export to .docx"
        >
            <span className="material-symbols-outlined text-white text-base mr-1">
                {exporting ? 'hourglass_empty' : 'upload_file'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Export</span>
        </button>
    );
};

export default ExportDocxButton;