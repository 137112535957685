import React from 'react';
import { getNoteDragStyles } from './sidebarStyles';

function NotesList({
    notes,
    selectedNote,
    handleNoteSelect,
    handleDeleteNote,
    draggedNote,
    setDraggedNote,
    draggedOverNote,
    setDraggedOverNote,
    handleDrop,
    deleteConfirmation,
    setDeleteConfirmation,
    isPanelCollapsed
}) {
    if (isPanelCollapsed) return null;

    const handleDragStart = (e, note) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', note.id);
        setDraggedNote(note);
        setTimeout(() => {
            e.target.style.opacity = '0.5';
        }, 0);
    };

    const handleDragOver = (e, note) => {
        e.preventDefault();
        setDraggedOverNote(note);
    };

    const handleDragEnd = (e) => {
        e.target.style.opacity = '1';
        setDraggedNote(null);
        setDraggedOverNote(null);
    };

    return (
        <ul className="space-y-2 px-4">
            {notes.map((note) => (
                <li
                    key={note.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, note)}
                    onDragOver={(e) => handleDragOver(e, note)}
                    onDragEnd={handleDragEnd}
                    onDrop={(e) => handleDrop(e, note)}
                    className={`p-2 rounded cursor-pointer hover:bg-[#2a2a2a] hover:bg-opacity-50 transition duration-150 ease-in-out active:bg-opacity-80 active:bg-[#3a3a3a]
                        ${selectedNote && selectedNote.id === note.id ? 'bg-[#2a2a2a] bg-opacity-50 shadow-md' : ''}
                        ${getNoteDragStyles(note, draggedNote, draggedOverNote, notes)}`}
                    onClick={() => handleNoteSelect(note)}
                >
                    <div className="flex justify-between items-center">
                        <div className="flex items-center min-w-0 flex-grow">
                            <span className="mr-2 text-xs font-semibold bg-[#2a2a2a] text-white px-1 rounded flex-shrink-0">
                                {note.preset === 'Outpatient' ? 'OP' :
                                    note.preset === 'ID Consult' ? 'NEW' :
                                        note.preset === 'ID Follow Up' ? 'F/U' : ''}
                            </span>
                            <span 
                                className="truncate overflow-hidden text-ellipsis flex-grow text-[#e0e0e0]"
                                title={(note.title || 'Untitled Note').replace(/\d+/g, '')}
                            >
                                {(note.title || 'Untitled Note').replace(/\d+/g, '')}
                            </span>
                        </div>
                        <div className="flex-shrink-0 ml-2">
                            {deleteConfirmation === note.id ? (
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteNote(note.id);
                                        }}
                                        className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                    >
                                        <span className="material-icons text-sm">check</span>
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteConfirmation(null);
                                        }}
                                        className="text-[#ababab] hover:text-[#e0e0e0] transition duration-150 ease-in-out"
                                    >
                                        <span className="material-icons text-sm">close</span>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteConfirmation(note.id);
                                    }}
                                    className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                >
                                    <span className="material-symbols-outlined text-sm">delete</span>
                                </button>
                            )}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default NotesList; 