import React from 'react';

const presets = [
    { full: 'ID Consult', short: 'ID' },
    { full: 'ID Follow Up', short: 'Follow' },
    { full: 'Outpatient', short: 'Outpt' }
];

const PresetSelector = ({ currentPreset, onPresetChange }) => {
    return (
        <div className="inline-flex rounded-md shadow-sm" role="group">
            {presets.map((preset, index) => (
                <button
                    key={preset.full}
                    type="button"
                    disabled={currentPreset === preset.full}
                    onClick={() => onPresetChange(preset.full)}
                    className={`
                        px-4 py-2 text-sm font-medium
                        ${index === 0 ? 'rounded-l-lg' : ''}
                        ${index === presets.length - 1 ? 'rounded-r-lg' : ''}
${currentPreset === preset.full
                            ? 'bg-[#1976d2] text-white'
                            : 'bg-[#2a2a2a] text-[#e0e0e0] hover:bg-[#323232] hover:text-white'
}
                        focus:z-10 focus:ring-1 focus:ring-white focus:text-white
                        transition-colors duration-200 ease-in-out flash-button
                        border-t border-b ${index === 0 ? 'border-l' : ''} ${index === presets.length - 1 ? 'border-r' : ''}
                        border-[#404040]
                    `}
                >
                    <span className="hidden sm:inline">{preset.full}</span>
                    <span className="sm:hidden">{preset.short}</span>
                </button>
            ))}
        </div>
    );
};

export default PresetSelector;