import { useState, useEffect } from 'react';

export function useVisualViewport() {
    const [bottomOffset, setBottomOffset] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.visualViewport) {
                const viewport = window.visualViewport;
                // Calculate the offset from the bottom of the visual viewport
                // to the bottom of the layout viewport
                const offsetBottom = Math.max(0,
                    window.innerHeight - (viewport.height + viewport.offsetTop)
                );
                setBottomOffset(offsetBottom);
            }
        };

        // Initial calculation
        handleResize();

        // Add event listeners for both viewport and window
        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', handleResize);
            window.visualViewport.addEventListener('scroll', handleResize);
        }
        
        // Also listen for window resize for orientation changes
        window.addEventListener('resize', handleResize);

        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', handleResize);
                window.visualViewport.removeEventListener('scroll', handleResize);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return bottomOffset;
} 