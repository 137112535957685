import React, { createContext, useState, useContext, useEffect } from 'react';
import { addDays } from 'date-fns';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

const SettingsContext = createContext();

export const useSettings = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [settings, setSettings] = useState({
        defaultPreset: 'ID Consult',
        signoutDate: addDays(new Date(), 1),
        signoutPerson: '',
        aiProvider: 'claude',
    });

    const loadSettings = async () => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                return;
            }

            const response = await fetch(`${SERVER_URL}/api/settings`, {
                headers: {
                    'x-auth-token': sessionStorage.getItem('token')
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const loadedSettings = await response.json();
            setSettings(prevSettings => ({
                ...prevSettings,
                ...loadedSettings,
                signoutDate: loadedSettings.signoutDate ? new Date(loadedSettings.signoutDate) : null,
            }));
        } catch (error) {
            console.error('Error loading settings:', error);
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);

    const updateSettings = async (newSettings) => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const updatedSettings = {
                ...settings,
                ...newSettings,
                signoutDate: newSettings.signoutDate ? new Date(newSettings.signoutDate) : null,
            };

            const response = await fetch(`${SERVER_URL}/api/settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify(Object.fromEntries(
                    Object.entries(updatedSettings).map(([key, value]) => [
                        key,
                        value instanceof Date ? value.toISOString() : value
                    ])
                ))
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setSettings(updatedSettings);
        } catch (error) {
            console.error('Error saving settings:', error);
            throw error;
        }
    };

    const updateSetting = async (key, value) => {
        await updateSettings({ [key]: value });
    };

    return (
        <SettingsContext.Provider value={{ settings, updateSettings, loadSettings, updateSetting }}>
            {children}
        </SettingsContext.Provider>
    );
};