// Organisms
const organismTerms = [
    // Gram-negative bacteria
    'A. baumannii', 'Acinetobacter baumannii',
    'Bartonella',
    'Campylobacter',
    'E. cloacae', 'Enterobacter cloacae', 
    'E. coli', 'Escherichia coli',
    'H. influenzae', 'Haemophilus influenzae',
    'K. pneumoniae', 'Klebsiella pneumoniae',
    'K. oxytoca', 'Klebsiella oxytoca',
    'Legionella',
    'M. catarrhalis', 'Moraxella catarrhalis',
    'Moraxella',
    'N. gonorrhoeae', 'Neisseria gonorrhoeae',
    'N. meningitidis', 'Neisseria meningitidis',
    'Pasteurella',
    'P. aeruginosa', 'Pseudomonas aeruginosa',
    'Salmonella',
    'S. marcescens', 'Serratia marcescens',
    'Serratia',
    'S. maltophilia', 'Stenotrophomonas maltophilia',
    'Stenotrophomonas',

    // Gram-positive bacteria
    'C. difficile', 'Clostridioides difficile',
    'E. faecalis', 'Enterococcus faecalis',
    'E. faecium', 'Enterococcus faecium',
    'Group B Streptococcus',
    'L. monocytogenes', 'Listeria monocytogenes',
    'MRSA', 'Methicillin-resistant Staphylococcus aureus',
    'S. agalactiae', 'Streptococcus agalactiae',
    'S. aureus', 'Staphylococcus aureus',
    'S. epidermidis', 'Staphylococcus epidermidis',
    'S. pneumoniae', 'Streptococcus pneumoniae',
    'S. pyogenes', 'Streptococcus pyogenes',
    'Viridans group Streptococci',
    'VRE', 'Vancomycin-resistant Enterococcus',

    // Fungi
    'A. fumigatus', 'Aspergillus fumigatus',
    'C. albicans', 'Candida albicans',
    'C. auris', 'Candida auris',
    'C. glabrata', 'Candida glabrata',
    'C. neoformans', 'Cryptococcus neoformans',

    // Viruses
    'CMV', 'Cytomegalovirus',
    'COVID-19',
    'EBV', 'Epstein-Barr Virus',
    'HSV', 'Herpes Simplex Virus',
    'HIV',
    'Influenza',
    'RSV', 'Respiratory Syncytial Virus',
    'SARS-CoV-2',

    // Parasites
    'Anaplasma',
    'Babesia',
    'Ehrlichia',
    'Toxoplasma',
];

// Antimicrobials
const antimicrobialTerms = [
    'Amikacin',
    'Amoxicillin-clavulanate',
    'Amoxicillin',
    'Ampicillin-sulbactam',
    'Ampicillin',
    'Azithromycin',
    'Cefadroxil',
    'Cefazolin',
    'Cefepime',
    'Cefiderocol',
    'Cefixime',
    'Ceftaroline',
    'Ceftazidime-avibactam',
    'Ceftazidime',
    'Ceftolozane-tazobactam',
    'Ceftolozane',
    'Ceftriaxone',
    'Ciprofloxacin',
    'Clarithromycin',
    'Clindamycin',
    'Daptomycin',
    'Doxycycline',
    'Ertapenem',
    'Erythromycin',
    'Fosfomycin',
    'Gentamicin',
    'Imipenem',
    'Levofloxacin',
    'Linezolid',
    'Meropenem',
    'Metronidazole',
    'Moxifloxacin',
    'Nafcillin',
    'Nitrofurantoin',
    'Oxacillin',
    'Penicillin',
    'Piperacillin-tazobactam',
    'Piperacillin',
    'Rifampin',
    'Tedizolid',
    'TMP-SMX',
    'Tobramycin',
    'Trimethoprim-sulfamethoxazole',
    'Vancomycin'
];

// Helper function to create highlight terms
const createHighlightTerms = (terms, color) => {
    return terms.reduce((acc, term) => {
        acc[term.toLowerCase()] = {
            color: color,
            // No sections specified means it applies to all sections
        };
        return acc;
    }, {});
};

// Base highlight terms
const baseHighlightTerms = {
    'blood culture': {
        color: '#ff9800',  // Orange
        sections: ['labs']
    },
    'positive': {
        color: '#ef5350',  // Red
        sections: ['labs']
    },
    'negative': {
        color: '#9e9e9e',  // Light grey
        sections: ['labs']
    },
    '***': {
        color: '#ff69b4', //Pink
    }
};

// Combine all highlight terms
const highlightTerms = {
    ...baseHighlightTerms,
    ...createHighlightTerms(organismTerms, '#e040fb'),
    ...createHighlightTerms(antimicrobialTerms, '#81c784')
};

export default highlightTerms;