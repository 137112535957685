import React from 'react';

function SidebarButtons({ 
    isPanelCollapsed, 
    handleNewNote, 
    handleSignout, 
    isNewNoteAnimating,
    notesExist 
}) {
    const handleNewNoteClick = () => {
        handleNewNote();
    };

    if (isPanelCollapsed) {
        return (
            <button
                onClick={handleNewNoteClick}
                title="New Note"
                className={`w-full flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white ${
                    isNewNoteAnimating ? 'animate-click' : ''
                }`}
            >
                <span className="material-symbols-outlined">note_stack_add</span>
            </button>
        );
    }

    return notesExist ? (
        <div className="flex space-x-2">
            <button
                onClick={handleSignout}
                title="Generate Signout"
                className="flex-1 flex items-center justify-center p-2 bg-[#2e7d32] hover:bg-[#388e3c] rounded transition duration-150 ease-in-out shadow-md text-white flash-button"
            >
                <span className="material-icons">assignment_ind</span>
                Signout
            </button>
            <button
                onClick={handleNewNoteClick}
                title="New Note"
                className={`flex-1 flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white flash-button ${
                    isNewNoteAnimating ? 'animate-click' : ''
                }`}
            >
                <span className="material-symbols-outlined">note_stack_add</span>
                New
            </button>
        </div>
    ) : (
        <button
            onClick={handleNewNoteClick}
            title="New Note"
            className={`w-full flex items-center justify-center p-2 bg-[#1976d2] hover:bg-[#2196f3] rounded transition duration-150 ease-in-out shadow-md text-white flash-button ${
                isNewNoteAnimating ? 'animate-click' : ''
            }`}
        >
            <span className="material-symbols-outlined">note_stack_add</span>
            New Note
        </button>
    );
}

export default SidebarButtons; 