import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { sectionLabels } from '../NoteEditorSections';
import { presetSections } from '../../../App';

const OCRButton = ({ note, updateNote }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [ocrText, setOcrText] = useState('');
    const fileInputRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');

    useEffect(() => {
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }, []);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            processImage(file);
        }
    };

    const handleCameraCapture = () => {
        if (!isMobile) {
            alert("Camera capture is optimized for mobile devices. On desktop, please use the 'Upload Image' option.");
            return;
        }
        
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.capture = 'environment';
        
        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                processImage(file);
            }
        };
        
        input.click();
    };

    const preprocessImage = async (imageFile) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                
                // Draw original image
                ctx.drawImage(img, 0, 0);
                
                // Convert to grayscale
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;
                for (let i = 0; i < data.length; i += 4) {
                    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                    data[i] = data[i + 1] = data[i + 2] = avg;
                }
                ctx.putImageData(imageData, 0, 0);
                
                // Increase contrast (simplified)
                ctx.globalCompositeOperation = 'multiply';
                ctx.fillStyle = 'rgba(255,255,255,0.1)';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                canvas.toBlob(resolve, 'image/jpeg', 0.9);
            };
            img.src = URL.createObjectURL(imageFile);
        });
    };

    const processImage = async (imageFile) => {
        setIsLoading(true);
        setError(null);
        try {
            const processedImage = await preprocessImage(imageFile);
            const endpoint = process.env.REACT_APP_AZURE_VISION_ENDPOINT.replace(/\/$/, '');
            const response = await axios.post(
                `${endpoint}/computervision/imageanalysis:analyze?api-version=2024-02-01&features=read`,
                processedImage,
                {
                    headers: {
                        'Content-Type': 'application/octet-stream',
                        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_VISION_KEY,
                    }
                }
            );

            if (response.data.readResult && response.data.readResult.blocks) {
                const extractedText = response.data.readResult.blocks
                    .flatMap(block => block.lines)
                    .map(line => line.text)
                    .join('\n');
                setOcrText(extractedText);
            } else {
                console.error('Unexpected response structure:', response.data);
                setError('Failed to extract text from the image.');
            }

        } catch (error) {
            console.error('Error processing image:', error);
            setError('Failed to process image. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyText = () => {
        navigator.clipboard.writeText(ocrText);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    const resetStates = () => {
        setIsOpen(false);
        setOcrText('');
        setError(null);
        setCopySuccess(false);
    };

    const handlePushToSection = () => {
        if (!selectedSection || !ocrText) return;

        try {
            const currentContent = note[selectedSection]?.trim() || '';
            const newContent = ocrText.trim();
            const updatedContent = currentContent
                ? `${currentContent}\n\n${newContent}`
                : newContent;
            
            updateNote(selectedSection, updatedContent);
            resetStates();
        } catch (error) {
            console.error('Error pushing to section:', error);
            setError('Failed to update note section. Please try again.');
        }
    };

    // Get sections for the current note's preset
    const availableSections = note.preset ? presetSections[note.preset] : [];

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="flex items-center justify-center px-3 py-1.5 rounded-md bg-[#2a2a2a] hover:bg-[#323232] border border-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
                title="Scan Document"
            >
                <span className="material-icons text-white text-base mr-1">
                    image_search
                </span>
                <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Scan</span>
            </button>
            {isOpen && (
                <div className="fixed inset-0 bg-[#121212] bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-[#1e1e1e] p-4 sm:p-6 rounded-lg shadow-xl border border-[#323232] w-full max-w-2xl relative">
                        <button
                            onClick={resetStates}
                            className="absolute top-2 right-2 text-[#ababab] hover:text-white focus:outline-none focus:ring-1 focus:ring-white"
                            aria-label="Close"
                        >
                            <span className="material-icons">close</span>
                        </button>
                        <h2 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 text-gray-100 text-center">Scan Document</h2>
                        <div className="flex flex-col items-center mb-3 sm:mb-4">
                            <div className="flex flex-wrap justify-center gap-2 sm:space-x-4 mb-3 sm:mb-4 w-full">
                                <button
                                    onClick={() => fileInputRef.current.click()}
                                    className="px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white border border-[#323232] flash-button"
                                >
                                    <span className="material-icons text-white text-base mr-1">upload</span>
                                    <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Upload Image</span>
                                </button>
                                {isMobile && (
                                    <button
                                        onClick={handleCameraCapture}
                                        className="px-4 py-2 bg-[#2a2a2a] hover:bg-[#323232] text-white rounded transition duration-150 ease-in-out flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white border border-[#323232] flash-button"
                                    >
                                        <span className="material-icons text-white text-base mr-1">camera_alt</span>
                                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Take Photo</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleImageUpload}
                            accept="image/*"
                            className="hidden"
                        />
                        {isLoading && (
                            <div className="flex items-center justify-center mb-4">
                                <span className="material-icons text-white text-base animate-spin mr-1">hourglass_empty</span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Processing...</span>
                            </div>
                        )}
                        {error && <p className="text-red-500 mb-2">{error}</p>}
                        <textarea
                            value={ocrText}
                            onChange={(e) => setOcrText(e.target.value)}
                            className="w-full h-36 sm:h-48 p-2 bg-[#242424] border border-[#323232] text-white rounded mb-3 sm:mb-4"
                        />
                        <div className="flex flex-col sm:flex-row items-center gap-2 flex-wrap">
                            <div className="flex items-center gap-2 flex-wrap">
                                <div className="flex items-center gap-2">
                                    <span className="text-[#e0e0e0] text-sm whitespace-nowrap">Push to:</span>
                                    <select
                                        value={selectedSection}
                                        onChange={(e) => setSelectedSection(e.target.value)}
                                        className="bg-[#242424] text-white border border-[#323232] rounded px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-white w-[150px]"
                                    >
                                        <option value="">Select section...</option>
                                        {Object.entries(sectionLabels)
                                            .filter(([key]) => availableSections?.includes(key))
                                            .map(([key, label]) => (
                                                <option key={key} value={key}>{label}</option>
                                            ))
                                        }
                                    </select>
                                    <button
                                        onClick={handlePushToSection}
                                        disabled={!selectedSection || !ocrText}
                                        className="inline-flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] focus:outline-none focus:ring-1 focus:ring-white flash-button transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed border border-[#323232]"
                                        title="Push to Section"
                                    >
                                        <span className="material-icons text-white text-base">
                                            compare_arrows
                                        </span>
                                        <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline ml-1">Push</span>
                                    </button>
                                </div>
                            </div>
                            <button
                                onClick={handleCopyText}
                                className="inline-flex items-center justify-center px-2 py-1 rounded-md bg-[#2a2a2a] hover:bg-[#323232] focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out ml-auto border border-[#323232] flash-button"
                                title="Copy"
                            >
                                <span className="material-icons text-white text-base">
                                    {copySuccess ? 'check_circle' : 'content_copy'}
                                </span>
                                <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline ml-1">Copy</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default OCRButton;
